import isCordova from "../../helpers/cordova-check";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  optionOpenInterestData: null,
  optionsBlockQuantityData: null,
  optionsBlockQualityData: null,
  optionsIvData: null,
  optionsAverageVolData: null,
  territoryData: null,
  interestsData: null,
  momentumData: null,
  exposureData: null,
  optionsSearchData: null,
  refreshLoading: false,
  showFaq: false,
};

const setOptionsOpenInterestData = (state, action) => {
  return {
    ...state,
    optionOpenInterestData: action.data,
  };
};

const setOptionsBlockQuantityData = (state, action) => {
  return {
    ...state,
    optionsBlockQuantityData: action.data,
  };
};

const setOptionsBlockQualityData = (state, action) => {
  return {
    ...state,
    optionsBlockQualityData: action.data,
  };
};

const setOptionsIvData = (state, action) => {
  return {
    ...state,
    optionsIvData: action.data,
  };
};

const setOptionsAverageVolData = (state, action) => {
  return {
    ...state,
    optionsAverageVolData: action.data,
  };
};

const setTerritoryData = (state, action) => {
  return {
    ...state,
    territoryData: action.data,
  };
};

const setInterestsData = (state, action) => {
  return {
    ...state,
    interestsData: action.data,
  };
};

const setMomentumData = (state, action) => {
  return {
    ...state,
    momentumData: action.data,
  };
};

const setExposureData = (state, action) => {
  return {
    ...state,
    exposureData: action.data,
  };
};

const setOptionsSearchData = (state, action) => {
  return {
    ...state,
    optionsSearchData: action.data,
  };
};

const setRefreshLoading = (state, action) => {
  return {
    ...state,
    refreshLoading: action.data,
  };
};

const setShowFaqq = (state, action) => {
  let link = "https://spiking.com/help";
  if (isCordova()) window.cordova.InAppBrowser.open(link, "_system");
  else window.open(link, "_blank");
  return {
    ...state,
    showFaq: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REFRESH_LOADING:
      return setRefreshLoading(state, action);
    case actionTypes.GET_OPTIONS_OPEN_INTEREST_DATA_DONE:
      return setOptionsOpenInterestData(state, action);
    case actionTypes.GET_OPTIONS_BLOCK_QUANTITY_DATA_DONE:
      return setOptionsBlockQuantityData(state, action);
    case actionTypes.GET_OPTIONS_BLOCK_QUALITY_DATA_DONE:
      return setOptionsBlockQualityData(state, action);
    case actionTypes.GET_IV_CHANGE_DATA_DONE:
      return setOptionsIvData(state, action);
    case actionTypes.GET_OPTIONS_AVERAGE_VOLUME_DATA_DONE:
      return setOptionsAverageVolData(state, action);
    case actionTypes.GET_TERRITORY_DATA_DONE:
      return setTerritoryData(state, action);
    case actionTypes.GET_OPTIONS_INTEREST_DATA_DONE:
      return setInterestsData(state, action);
    case actionTypes.GET_MOMENTUM_DATA_DONE:
      return setMomentumData(state, action);
    case actionTypes.GET_EXPOSURE_DATA_DONE:
      return setExposureData(state, action);
    case actionTypes.GET_OPTIONS_SEARCH_DATA_DONE:
      return setOptionsSearchData(state, action);
    case actionTypes.SHOW_FAQ:
      return setShowFaqq(state, action);
    default:
      return state;
  }
};

export default reducer;
