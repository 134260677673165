import React from "react";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { PAGE_LINKS } from "./constants";

// TODO Show AppBar

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  bold: {
    fontWeight: 700,
  },
  header: {
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1,
    },
    // color: theme.palette.text.primary
  },
  body: {
    fontFamily: "Open Sans",
    fontWeight: 300,
    // color: "rgba(255,255,255,0.7)",
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      width: 250,
    },
  },
  link: {
    textDecoration: "none",
    "& > p": {
      fontWeight: 300,
      color: theme.palette.info.light,
    },
    "&:hover": {
      "& > p": {
        fontWeight: 700,
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: 700,
    letterSpacing: "0.3px",
    textTransform: "none",
    alignSelf: "flex-start",
    padding: theme.spacing(1.25, 4),
  },
  disagreeButton: {
    // color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  mobileColumn: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const ErrorPage = (props) => {
  const { data, history } = props;
  const classes = useStyles();

  const onRefreshClick = () => {
    history.go(0);
  };

  const handleSupportClick = () => {
    history.push("/");
    window.open("https://spi.ke/help", "_blank");
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid className={classes.root} container>
          {/* Content */}
          <Grid
            item
            xs={12}
            md={7}
            container
            justify="center"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              maxWidth={{ xs: 350, md: "auto" }}
            >
              <Typography
                className={classes.bold}
                color="primary"
                variant="overline"
              >
                {data.overline}
              </Typography>
              <Typography className={classes.header} variant="h2">
                {data.header}
              </Typography>
              <Box my={4}>
                {data.body.map((line) => (
                  <Typography variant="h5" className={classes.body}>
                    {line}
                  </Typography>
                ))}
              </Box>

              {/* Links and Action Buttons */}
              {data.showLinkBar ? (
                <>
                  <Box className={classes.mobileColumn} mb={5}>
                    {PAGE_LINKS.map((link) => (
                      <Box mr={3} mb={{ xs: 1, md: 0 }}>
                        <Link to={link.href} className={classes.link}>
                          <Typography>{link.title}</Typography>
                        </Link>
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    <Link to="/spiking-up-down?" className={classes.link}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                      >
                        Return Home
                      </Button>
                    </Link>

                    {/* TODO Ask support link */}
                    {/* <Link to="https://love.spiking.com/hc/en-us/requests/new" className={classes.link}> */}
                    <Button
                      className={clsx(classes.button, classes.disagreeButton)}
                      variant="outlined"
                      color="primary"
                      onClick={handleSupportClick}
                    >
                      Ask Support
                    </Button>
                    {/* </Link> */}
                  </Box>
                </>
              ) : (
                <>
                  {data.showRefresh && (
                    <Button
                      onClick={onRefreshClick}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      Refresh
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Grid>

          {/* Spacer */}
          <Grid item md={1} />

          {/* Image */}
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems="center"
            justify="center"
          >
            <img className={classes.image} src={data.image} alt="helper icon" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

ErrorPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withRouter(ErrorPage);
