import React, { useEffect, useRef, useState } from "react";
import banner from "../../images/advertise_banner.png";

function Banner() {
  const [bannerVisible, setBannerVisible] = useState(false);
  const bannerContainer = useRef();

  function getCookie(cName) {
    const name = `${cName}=`;
    const cookiesDecoded = decodeURIComponent(document.cookie);
    const cookiesArr = cookiesDecoded.split("; ");
    let res;
    cookiesArr.forEach((value) => {
      if (value.indexOf(name) === 0) res = value.substring(name.length);
    });
    return res;
  }

  const activeBanner = () => {
    setBannerVisible(true);
  };

  const closeBanner = () => {
    const currentTime = new Date();
    const expiredDate = new Date();

    expiredDate.setTime(currentTime.getTime() + 24 * 60 * 60 * 1000);

    document.body.style.overflow = "auto";
    document.cookie = `banner=true;expires=${expiredDate.toGMTString()}`;
    setBannerVisible(false);
  };

  useEffect(() => {
    const cookieBanner = getCookie("banner");

    if (!cookieBanner) {
      activeBanner();
    }
  }, []);

  return (
    <div
      ref={bannerContainer}
      className={`advertise-banner-container ${
        bannerVisible ? "advertise-banner-visible-active" : ""
      }`}
    >
      <div onClick={closeBanner} className="h-full w-full absolute z-0" />
      <div className="relative z-[1]">
        <button
          onClick={closeBanner}
          className="absolute bottom-full mb-1 right-6"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.60937 2.66667C3.34905 2.40634 2.92699 2.40634 2.66667 2.66667V2.66667C2.40634 2.92699 2.40634 3.34905 2.66667 3.60938L7.05729 8L2.66667 12.3906C2.40634 12.6509 2.40635 13.073 2.66667 13.3333V13.3333C2.92699 13.5937 3.34905 13.5937 3.60937 13.3333L8 8.94271L12.3906 13.3333C12.6509 13.5937 13.073 13.5937 13.3333 13.3333V13.3333C13.5937 13.073 13.5937 12.6509 13.3333 12.3906L8.94271 8L13.3333 3.60938C13.5937 3.34905 13.5937 2.92699 13.3333 2.66667V2.66667C13.073 2.40635 12.6509 2.40635 12.3906 2.66667L8 7.05729L3.60937 2.66667Z"
              fill="#97A0AF"
            />
          </svg>
        </button>
        <a
          href="https://spiking.com/purchase/spiking-ai"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={banner}
            width={800}
            objectFit="cover"
            className=" cursor-pointer"
            alt="banner"
          />
        </a>
      </div>
    </div>
  );
}

export default Banner;
